import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let FashLogo = (props) => (
	<SvgIcon {...props} viewBox={"0 0 125 34"}>
		<title>Fash</title>
		<path
			d="M26.08 11.18H6.31a5.16 5.16 0 0 1-5.16-5.16h0A5.16 5.16 0 0 1 6.31.86h19.78a5.16 5.16 0 0 1 5.16 5.16h0c-.01 2.85-2.32 5.16-5.17 5.16z"
			fill="#00a1f2"
		/>
		<path
			d="M15.95 22.57H6.31a5.16 5.16 0 0 1-5.16-5.16h0a5.16 5.16 0 0 1 5.16-5.16h9.65a5.16 5.16 0 0 1 5.16 5.16h0c-.01 2.85-2.32 5.16-5.17 5.16z"
			fill="#fb0"
		/>
		<circle cx="6.26" cy="28.54" r="4.99" fill="#f66" />
		<path
			d="M41.41 5.04h16.05v5.03H46.71v4.9h9.66V20h-9.66v9.38h-5.3V5.04zm24.61 0h6.12l9.11 24.34H75.4l-1.87-5.44h-8.91l-1.87 5.44H56.9l9.12-24.34zm5.82 14.08l-1.77-5.1-.82-2.99h-.34l-.82 2.99-1.77 5.1h5.52zm19.99 10.81c-2.27 0-4.22-.61-5.87-1.82-1.64-1.21-2.8-2.95-3.48-5.22l5-1.97c.29 1.16.78 2.11 1.46 2.86s1.64 1.12 2.89 1.12c.41 0 .8-.04 1.17-.14.37-.09.7-.23.99-.42s.52-.44.7-.73.27-.63.27-1.02a2.53 2.53 0 0 0-.22-1.07c-.15-.33-.39-.63-.73-.92-.34-.28-.79-.57-1.36-.85s-1.28-.58-2.14-.9l-1.5-.54c-.64-.23-1.29-.54-1.96-.93-.67-.4-1.28-.88-1.82-1.45s-.99-1.22-1.33-1.96-.51-1.56-.51-2.46c0-.97.19-1.89.58-2.75.38-.86.93-1.6 1.63-2.23.7-.62 1.55-1.12 2.55-1.48s2.1-.54 3.3-.54c1.27 0 2.36.16 3.26.49.91.33 1.68.75 2.31 1.26s1.14 1.08 1.51 1.72.66 1.25.87 1.84l-4.59 1.94c-.25-.72-.65-1.31-1.19-1.75s-1.25-.66-2.11-.66c-.91 0-1.63.19-2.16.56s-.8.86-.8 1.45c0 .61.27 1.16.82 1.63.54.48 1.51.95 2.89 1.43l1.5.51c2.06.7 3.66 1.64 4.79 2.82s1.7 2.77 1.7 4.76c0 1.27-.26 2.37-.77 3.3s-1.17 1.7-1.97 2.31a8.62 8.62 0 0 1-2.72 1.38c-1 .27-1.99.43-2.96.43zm12.2-24.89h5.3v9.38h9.32V5.04h5.3v24.34h-5.3v-9.93h-9.32v9.93h-5.3V5.04z"
			fill="#222"
		/>
	</SvgIcon>
);

FashLogo = memo(FashLogo);
FashLogo.displayName = "FashLogo";
FashLogo.muiName = "FashLogo";

export default FashLogo;
